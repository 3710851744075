//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import PaymentMethodCC from '~/components/checkout/components/PaymentMethodCC.vue';
import PaymentMethodAffirm from '~/components/checkout/components/PaymentMethodAffirm.vue';
import PaymentMethodWallet from '~/components/checkout/components/PaymentMethodWallet.vue';

const AFFIRM_MERCHANT = 'affirm';
const STRIPE_MERCHANT = 'stripe';

export default {
  name: 'PaymentMethodsContainer',
  data: () => ({
    selectedMethodCid: '',
    isLoading: true,
    timer: null,
  }),
  computed: {
    ...mapState({
      country: state => state.checkout.shipping.country,
    }),
    paymentMethods() {
      return [
        {
          component: PaymentMethodWallet,
          cid: 'walletPaymentCid',
          merchant: STRIPE_MERCHANT,
        },
        {
          component: PaymentMethodCC,
          cid: 'creditCardCid',
          merchant: STRIPE_MERCHANT,
        },
        {
          component: PaymentMethodAffirm,
          cid: 'affirmPrepayCid',
          merchant: AFFIRM_MERCHANT,
        },
      ];
    },
  },
  methods: {
    toggleMethod(methodCid) {
      this.selectedMethodCid = this.selectedMethodCid !== methodCid ? methodCid : '';
    },
    displayConditions(merchant) {
      return !(merchant === AFFIRM_MERCHANT && this.country === 'CA');
    },
    handleLoading() {
      this.isLoading = false;
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.isLoading = false;
    }, 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
